/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useContext } from 'react';

import { Helmet } from 'react-helmet';
import { AUTHOR_NAME } from '../../constants';
import { GlitchContext } from '../../contexts/GlitchContext';
import { BackgroundColorTheme } from '../../templates/Page';
import { clsx } from '../../utils/utils';
import * as styles from './Header.module.scss';
import SmartLink from './SmartLink';

export interface HeaderProps {
  sticky?: boolean;
  logoImage: string;
  theme?: BackgroundColorTheme;
}

const Header = ({ sticky, logoImage, theme }: HeaderProps): React.ReactElement => {
  const addGlitchEffect = useContext(GlitchContext);

  return (
    <>
      {sticky && (
        <Helmet>
          <html className={styles.html}></html>
        </Helmet>
      )}
      <header
        className={clsx(styles.header, sticky && styles.stickyHeader)}
        data-theme={addGlitchEffect ? 'glitch' : 'light'}
      >
        <div className={styles.headerContainer}>
          <div className={styles.logoWrapper}>
            <SmartLink to="/" className={styles.logoContainer}>
              <img src={logoImage} alt="" />
              <div className={styles.visuallyHidden}>{AUTHOR_NAME}</div>
            </SmartLink>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
