const BUSINESS_EMAIL_BLACKLIST = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'outlook.com',
  'comcast.net',
  'icloud.com',
  'msn.com',
  'hotmail.co.uk',
  'live.com',
];

export function isBusinessEmail(email: string): boolean {
  const domain = email.split('@')[1];
  return !BUSINESS_EMAIL_BLACKLIST.includes(domain);
}
