import { graphql } from 'gatsby';
import React, { useContext } from 'react';
import { GlitchContext } from '../../../contexts/GlitchContext';
import { BackgroundColorTheme } from '../../../templates/Page';
import { clsx, replaceNewLinesWithBr } from '../../../utils/utils';
import { CommonModuleProps } from '../../ModulesContent';
import ButtonLink from '../ButtonLink';
import ModuleLayout from '../ModuleLayout';
import * as styles from './CtaModule.module.scss';

export const CtaModuleFragment = graphql`
  fragment CtaModule on SanityGtgvBookCtaModule {
    title
    text
    buttonText
  }
`;

export type CtaModuleProps = {
  title: string;
  text?: string;
  buttonText: string;
  className?: string;
};

export function getModuleBgColor(props: CtaModuleProps): BackgroundColorTheme {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return 'white';
}

function CtaModule(props: CtaModuleProps & CommonModuleProps): React.ReactElement {
  const { title, text, buttonText, className, moduleId } = props;

  const addGlitchEffect = useContext(GlitchContext);

  return (
    <ModuleLayout
      id={moduleId}
      className={clsx(className, styles.container, addGlitchEffect && styles.glitchEffect)}
      contentClassName={clsx(styles.contentContainer)}
      currentModuleBgColor={getModuleBgColor(props)}
    >
      <div className={clsx(styles.contentWrapper, styles.centerAligned)}>
        <div className={clsx(styles.textContainer)}>
          {addGlitchEffect && (
            <>
              <div className={clsx(styles.mockBackground, styles.pink)}></div>
              <div className={clsx(styles.mockBackground, styles.blue)}></div>
              <div className={clsx(styles.mockBackground, styles.black)}></div>
            </>
          )}
          <h2 className={styles.title}>{title}</h2>
          {text && <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>}
          <ButtonLink to={'#heroText'} className={styles.button} secondary>
            {buttonText}
          </ButtonLink>
        </div>
      </div>
    </ModuleLayout>
  );
}

export default CtaModule;
