import React, { ReactNode } from 'react';
import { clsx } from '../../utils/utils';

import * as styles from './Button.module.scss';

export interface ButtonProps extends Omit<React.HTMLAttributes<HTMLElement>, 'children'> {
  children: string | ReactNode;
  outlined?: boolean;
  secondary?: boolean;
  redButton?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'submit';
  className?: string;
}

const Button = ({
  children,
  outlined,
  secondary,
  redButton,
  onClick,
  type,
  className,
  ...restProps
}: ButtonProps): React.ReactElement => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        styles.button,
        outlined && styles.outlined,
        secondary && styles.secondary,
        redButton && styles.redButton,
        className,
      )}
      type={type === 'submit' ? 'submit' : 'button'}
      {...restProps}
    >
      {children}
    </button>
  );
};

export default Button;
