/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import './src/translation/i18n';

import React from 'react';

import { handleAbTest } from '@digital-spiders/ab-tests';
import { TrackingDataIframe } from '@digital-spiders/react-tracking-data';
import { storeHookPointTrackingData } from '@hook-point/tracking-data';
import * as Sentry from '@sentry/react';
import { GatsbyBrowser, navigate } from 'gatsby';
import { PAGE_TYPE_TO_PATH_FUNCTION, ROOT_DOMAIN, SITE_DOMAIN } from './src/constants';
import { GlitchContext } from './src/contexts/GlitchContext';
import { LocationContext } from './src/contexts/LocationContext';
import PreviewConnectorProvider from './src/preview/PreviewConnectorProvider';
import { PreviewStateProvider } from './src/preview/PreviewStateContext';
import { GlobalStateProvider } from './src/state/globalStateContext';

// You can delete this file if you're not using it
// export const onClientEntry = () => {
//     // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//     if (!(`IntersectionObserver` in window)) {
//       import(`intersection-observer`);
//     }
//   };
// import { I18nextProvider } from 'react-i18next';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  const abTestPathToRedirect = handleAbTest(props.pageContext?.abTest, PAGE_TYPE_TO_PATH_FUNCTION, {
    onCookieReady: () => {
      storeHookPointTrackingData({
        currentDomain: SITE_DOMAIN,
        rootDomain: ROOT_DOMAIN,
      });
    },
  });
  if (abTestPathToRedirect) {
    navigate(abTestPathToRedirect, { replace: true });
    // Do not render the page since we're redirecting the user
    return <div></div>;
  }

  return (
    /* <I18nextProvider i18n={i18n}> */
    <>
      <TrackingDataIframe
        rootDomain={ROOT_DOMAIN}
        onRender={() => {
          storeHookPointTrackingData({
            currentDomain: SITE_DOMAIN,
            rootDomain: ROOT_DOMAIN,
            serverReadyTimeout: 10000,
          }).catch(error => {
            console.error('Error storing tracking data', error);
            Sentry.captureException(error);
          });
        }}
      />
      <GlobalStateProvider>
        <LocationContext.Provider value={props.location}>
          <GlitchContext.Provider value={!!props.pageContext.addGlitchEffect}>
            <PreviewStateProvider>
              <PreviewConnectorProvider>{element}</PreviewConnectorProvider>
            </PreviewStateProvider>
          </GlitchContext.Provider>
        </LocationContext.Provider>
      </GlobalStateProvider>
    </>
    /* </I18nextProvider> */
  );
};

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
  }
};
