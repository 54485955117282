// extracted by mini-css-extract-plugin
export var active = "VideoTestimonialCard-module--active--eeba0";
export var backgroundImage = "VideoTestimonialCard-module--backgroundImage--45eb5";
export var blue = "VideoTestimonialCard-module--blue--e5e6d";
export var container = "VideoTestimonialCard-module--container--26f14";
export var icon = "VideoTestimonialCard-module--icon--9ad63";
export var iconOnTopLeftCorner = "VideoTestimonialCard-module--iconOnTopLeftCorner--3a18c";
export var mockBg = "VideoTestimonialCard-module--mockBg--9ba7c";
export var name = "VideoTestimonialCard-module--name--de6de";
export var overlay = "VideoTestimonialCard-module--overlay--c032f";
export var pink = "VideoTestimonialCard-module--pink--fa909";
export var playIconContainer = "VideoTestimonialCard-module--playIconContainer--539ac";
export var playIconOverlay = "VideoTestimonialCard-module--playIconOverlay--82797";
export var playIconWrapper = "VideoTestimonialCard-module--playIconWrapper--d07e7";
export var subtitle = "VideoTestimonialCard-module--subtitle--e31a3";
export var textContainer = "VideoTestimonialCard-module--textContainer--c1d6c";
export var videoReportContainer = "VideoTestimonialCard-module--videoReportContainer--ccc3e";
export var videoReportWrapper = "VideoTestimonialCard-module--videoReportWrapper--0754c";
export var withGlitch = "VideoTestimonialCard-module--withGlitch--14e6c";