// extracted by mini-css-extract-plugin
export var black = "CtaModule-module--black--5d594";
export var blue = "CtaModule-module--blue--c2553";
export var button = "CtaModule-module--button--f1886";
export var centerAligned = "CtaModule-module--centerAligned--fade1";
export var container = "CtaModule-module--container--99023";
export var contentWrapper = "CtaModule-module--contentWrapper--8f5ce";
export var glitchEffect = "CtaModule-module--glitchEffect--63866";
export var mockBackground = "CtaModule-module--mockBackground--a25f1";
export var text = "CtaModule-module--text--5fa78";
export var textContainer = "CtaModule-module--textContainer--0e913";
export var title = "CtaModule-module--title--3a8a5";