import React, { useContext, useState } from 'react';

import { pushVideoStartEvent, withDataLayer } from '@digital-spiders/tracking-data';
import { RiPlayFill } from 'react-icons/ri';
import { GlitchContext } from '../../contexts/GlitchContext';
import { SanityImageType } from '../../graphql-fragments/SanityImage';
import { clsx } from '../../utils/utils';
import EmbedModal from './EmbedModal';
import Image from './Image';
import ModalOverlay from './ModalOverlay';
import RatingStars from './RatingStars';
import * as styles from './VideoTestimonialCard.module.scss';

export interface VideoTestimonialCardProps {
  index: number;
  name: string;
  text: string;
  image: SanityImageType;
  videoUrl: string;
  isActive?: boolean;
  className?: string;
}

const VideoTestimonialCard = ({
  index,
  name,
  text,
  image,
  videoUrl,
  isActive,
  className,
}: VideoTestimonialCardProps): React.ReactElement => {
  const [modalOpenIndex, setModalOpenIndex] = useState<number | null>(null);

  const addGlitchEffect = useContext(GlitchContext);

  return (
    <div
      className={clsx(styles.videoReportContainer, isActive && styles.active, className)}
      onClick={() => {
        setModalOpenIndex(index);
        withDataLayer(pushVideoStartEvent({ videoUrl: videoUrl }));
      }}
    >
      <div className={clsx(styles.videoReportWrapper, addGlitchEffect && styles.withGlitch)}>
        {isActive && addGlitchEffect && <div className={clsx(styles.mockBg, styles.pink)}></div>}
        {isActive && addGlitchEffect && <div className={clsx(styles.mockBg, styles.blue)}></div>}
        <div className={styles.backgroundImage}>
          <Image image={image} />
          <div className={styles.overlay}></div>
        </div>
        <div className={styles.playIconContainer}>
          <div className={styles.playIconOverlay}></div>
          <div className={styles.playIconWrapper}>
            <RiPlayFill className={styles.icon} aria-label="play" role="img" />
          </div>
        </div>
        <div className={styles.textContainer}>
          <RatingStars rating={5} fillColor="#FFAC0C" starsSize={16} gapSize={4} />
          <h3 className={styles.name}>{name}</h3>
          <p className={styles.subtitle}>"{text}"</p>
        </div>
      </div>
      <ModalOverlay
        open={index === modalOpenIndex}
        onClose={() => {
          setModalOpenIndex(null);
        }}
      >
        <EmbedModal url={videoUrl} />
      </ModalOverlay>
    </div>
  );
};

export default VideoTestimonialCard;
